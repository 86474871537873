import { GetServerSideProps } from "next";
import cookies from "next-cookies";
import Router, { useRouter } from "next/router";
import React from "react";
import Login from "../../src/modules/core/components/Login";
// import config from "../src/config/config";
const LoginPage = () => {
  const router = useRouter();
  // console.log({
  //   object: [
  //     process.env.NEXT_PUBLIC_TransportHostname,
  //     process.env.NEXT_PUBLIC_TransportSSL,
  //     process.env.NEXT_PUBLIC_TransportPort,
  //     process.env.NEXT_PUBLIC_RealtimeHostname,
  //     process.env.NEXT_PUBLIC_RealtimeSSL,
  //     process.env.NEXT_PUBLIC_RealtimePort,
  //     process.env.NEXT_PUBLIC_RoutingHostname,
  //     process.env.NEXT_PUBLIC_RoutingSSL,
  //     process.env.NEXT_PUBLIC_RoutingPort,
  //     process.env.NEXT_PUBLIC_GoogleAPI,
  //     process.env.NEXT_PUBLIC_version,
  //     process.env.NEXT_PUBLIC_SENTRY_DSN,
  //   ],
  //   config,
  // });
  return <Login router={router} />;
};
export default LoginPage;
const redirectBooking = (context) => {
  const { loggedIn } = cookies(context);
  const redirectUrl = `/admin`;
  if (!process.browser && loggedIn) {
    return {
      redirect: {
        destination: redirectUrl,
        permanent: false,
      },
    };
  }
  if (process.browser && loggedIn) {
    Router.push(redirectUrl);
  }
  return {
    props: {
      redirect: true,
    },
  };
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return redirectBooking(context);
  // return { props: {} };
};
