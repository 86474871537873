import React, { useState, useEffect } from "react";
import Link from "next/link";
import uniqid from "uniqid";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/MailOutlined";
import PasswordIcon from "@material-ui/icons/LockOutlined";
import config from "../../../config/config.js";
import {
  loginWithPassword,
  setAskForOtpToFalse,
  onForgetPassword,
  resetLoggedInStat,
} from "../actions";
import { getLabels } from "../labels";
import { useLoginStyles } from "../styles/LoginStyles.js";
import { useDispatch, useSelector } from "react-redux";
import { AuthLayout } from "./fragments/AuthLayout.js";
import { ButtonSpinner } from "./fragments/ButtonSpinner.js";
import { OTPComp } from "./fragments/OTP/OTPComp.js";
import PasswordChangeComp from "./PasswordChangeComp.js";
// import { PublicClientApplication } from "@azure/msal-browser";

const emailRegex = /\S+@\S+\.\S+/;

const Login = ({ router }) => {
  const dispatch = useDispatch();
  const locale = useSelector(({ core }) => core.locale || "en");
  const isLoggingIn = useSelector(({ core }) => core.isLoggingIn);
  const askForOtp = useSelector(({ core }) => core.askForOtp);
  const fields = useSelector(({ core }) => core.fields);

  const classes = useLoginStyles();
  const labels = getLabels(locale);

  const [username, setUsername] = useState(fields?.username || "");
  const [password, setPassword] = useState(fields?.password || "");
  const [otp, setOtp] = useState("");
  const [otpLengthErr, setOtpLengthErr] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [chgPw, setChgPw] = useState(false);
  const [resetPw, setResetPw] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const isProd = process.env.NODE_ENV === "production";
  const azureBaseUrl = isProd
    ? `https://rehlati.om`
    : `https://mytrip-dev.emushrif.om`;

  useEffect(() => {
    const componentTitle = "Login";
    const appTitle = config.app.appName;
    document.title = `${componentTitle} - ${appTitle}`;
    isLoggingIn && dispatch(resetLoggedInStat());
  }, []);

  useEffect(() => {
    const id = router.query?.action;

    if (id === "changePassword") setChgPw(true);
    else setChgPw(false);
    if (id === "reset-password") setResetPw(true);
    else {
      setResetPw(false);
      setUsernameErr("");
    }
  }, [router.query]);

  const onResend = () => {
    if (!username || !password) return;

    dispatch(
      loginWithPassword({
        lang: locale,
        username,
        password,
        device_udid: uniqid(),
      })
    );
  };

  const onLogin = () => {
    if (!username || !password) {
      setUsernameErr(username ? "" : "Please provide your username");
      setPasswordErr(password ? "" : "Please provide your password");
    } else {
      setUsernameErr("");
      setPasswordErr("");
      if (askForOtp) {
        if (otp.toString().length < 5)
          setOtpLengthErr("Please enter all 5 digits");
        else {
          setOtpLengthErr("");
          dispatch(
            loginWithPassword({
              lang: locale,
              username,
              password,
              otp,
              device_udid: uniqid(),
            })
          );
        }
      } else {
        dispatch(
          loginWithPassword({
            lang: locale,
            username,
            password,
            device_udid: uniqid(),
          })
        );
      }
    }
  };

  const handleForgetPassword = () => {
    if (username) {
      if (!emailRegex.test(username))
        setUsernameErr("Please enter valid email address");
      else {
        setUsernameErr("");
        dispatch(
          onForgetPassword({
            lang: locale,
            email: username,
            router,
          })
        );
      }
    } else setUsernameErr("Please provide your email address");
  };

  // if (currentUser) {
  //  router.push(routes.bookings.path);
  //   return <div />;
  // }
  const [error, setError] = useState(null);
  // const [isAuthentecated, setIsAuthentecated] = useState(false);
  const [user, setUser] = useState({});

  // const publicClientApplication = new PublicClientApplication({
  //   auth: {
  //     clientId: config.appConfig.appId,
  //     redirectUri: config.appConfig.redirectUri,
  //     authority: config.appConfig.authority,
  //   },
  //   cache: {
  //     cacheLocation: "sessionStorage",
  //     storeAuthStateInCookie: true,
  //   },
  // });

  const loginWithAzure = async () => {
    // try {
    //   await publicClientApplication.loginPopup({
    //     scopes: config.appConfig.scopes,
    //     prompt: "select_account",
    //   });
    //   setIsAuthentecated(true);
    // } catch (error) {
    //   setError(error);
    //   setIsAuthentecated(false);
    //   setUser({});
    // }

    window.location.assign(`${azureBaseUrl}/api/auth/saml-login`);

    // fetch("https://mytrip-dev.emushrif.om/api/auth/saml-login")
    //   .then(async (response) => {
    //     // if (response.status !== 200) {
    //     // history.push('/login');
    //     await console.log("resssss", response);
    //     // }
    //   })
    //   .then(async (data) => {
    //     await console.log("data===", data);
    //   })
    //   .catch(async (error) => {
    //     await console.log("error ====", error);
    //   });
    // }
    // catch (error) {
    //   console.log("error ====1", error);
    //   setError(error);
    //   setIsAuthentecated(false);
    //   setUser({});
    // }
  };

  // const logout = () => {
  //   publicClientApplication.logoutPopup();
  // };
  return (
    // <AuthLayout />
    // <h1>lol</h1>
    <AuthLayout
      belowForm={
        resetPw || chgPw ? (
          <Link href={router.pathname}>
            <Typography
              display="block"
              variant="body2"
              className={classes.register1}
              color="primary"
            >
              {labels.backToLogin}
            </Typography>
          </Link>
        ) : showResend && askForOtp ? (
          <Typography
            display="block"
            variant="body2"
            className={classes.register1}
            color="primary"
            onClick={() => dispatch(setAskForOtpToFalse())}
          >
            {labels.backToLogin}
          </Typography>
        ) : (
          // <Link href={router.pathname}>
          <Typography
            display="block"
            variant="body2"
            className={classes.register1}
            color="primary"
            onClick={() => loginWithAzure()}
          >
            {labels.loginUsingAzure}
          </Typography>
          // </Link>
        )
      }
    >
      {chgPw ? (
        <PasswordChangeComp />
      ) : (
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="stretch"
          justify="flex-start"
        >
          {askForOtp ? (
            <OTPComp
              otp={otp}
              setOtp={setOtp}
              otpLengthErr={otpLengthErr}
              onResend={onResend}
              timerCompleted={() => dispatch(setAskForOtpToFalse())}
              showResend={showResend}
              setShowResend={setShowResend}
            />
          ) : (
            <>
              <Grid container item spacing={1}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    className={classes.margin}
                    variant="outlined"
                    fullWidth
                    label={resetPw ? labels.email : labels.usernameEmail}
                    value={username}
                    onChange={(event) => setUsername(event.currentTarget.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            height: "auto",
                          }}
                        >
                          <EmailIcon color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              {usernameErr && (
                <Typography color="error" className={classes.internalErr}>
                  {usernameErr}
                </Typography>
              )}

              {!resetPw && (
                <Grid container item spacing={1}>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      className={classes.margin}
                      variant="outlined"
                      fullWidth
                      label={labels.password}
                      value={password}
                      onChange={(event) =>
                        setPassword(event.currentTarget.value)
                      }
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              height: "auto",
                            }}
                          >
                            <PasswordIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {passwordErr && (
                <Typography color="error" className={classes.internalErr}>
                  {passwordErr}
                </Typography>
              )}
              <Grid item>
                {resetPw ? (
                  " "
                ) : (
                  // <Typography
                  //   component={Link}
                  //   to={{
                  //     pathname: router.pathname,
                  //     search: ``,
                  //   }}
                  //   display='block'
                  //   variant='body2'
                  //   className={classes.forgotPassword}
                  // >
                  //   {labels.login}
                  // </Typography>
                  <Typography
                    display="block"
                    variant="body2"
                    className={classes.forgotPassword}
                  >
                    <Link href={`${router.pathname}?action=reset-password`}>
                      <a>{labels.forgotPassword}</a>
                    </Link>
                  </Typography>
                )}
              </Grid>
            </>
          )}

          <Grid container item spacing={1}>
            <FormControl
              className={`${classes.formControl} ${classes.formControlLogin}`}
              fullWidth
            >
              {resetPw ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  // component="span"
                  color="primary"
                  className={classes.button}
                  onClick={handleForgetPassword}
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? ButtonSpinner : labels.resetPw}
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  // component="span"
                  color="primary"
                  className={classes.button}
                  onClick={onLogin}
                  disabled={isLoggingIn}
                >
                  {isLoggingIn
                    ? ButtonSpinner
                    : askForOtp
                    ? labels.verify
                    : labels.login}
                </Button>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
    </AuthLayout>
  );
};
export default Login;
