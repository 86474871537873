import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import PasswordIcon from "@material-ui/icons/LockOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { getLabels } from "../labels";
import { useLoginStyles } from "../styles/LoginStyles.js";
import { useDispatch, useSelector } from "react-redux";
import { ButtonSpinner } from "./fragments/ButtonSpinner.js";
import { onChangePassword, setAskForOtpToFalse } from "../actions";
import {
  StrongPasswordComp,
  PasswordPolicy,
} from "./fragments/StrongPasswordComp/StrongPasswordComp";
import { customValidators } from "../../../utils/validators";
import { Tooltip } from "@material-ui/core";

const PasswordChangeComp = () => {
  const locale = useSelector(({ core }) => core.locale || "en");
  const isLoggingIn = useSelector(({ core }) => core.isLoggingIn);
  const pwRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useLoginStyles();
  const labels = getLabels(locale);
  const router = useRouter();

  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [token, setToken] = useState("");

  const [pwErr, setPwErr] = useState("");

  useEffect(() => {
    const id = router?.query?.action;
    const tkn = router?.query?.token;

    customValidators(ValidatorForm);

    if (id === "changePassword") dispatch(setAskForOtpToFalse());

    if (tkn) setToken(tkn);
  }, []);

  const handleChangePassword = () => {
    if (password && conPassword) {
      setPwErr("");
      dispatch(
        onChangePassword({
          lang: locale,
          password,
          token,
          router,
        })
      );
    } else {
      setPwErr("Please enter password");
    }
  };
  return (
    <ValidatorForm
      // ref="form"
      onSubmit={handleChangePassword}
    >
      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="stretch"
        justify="flex-start"
      >
        <Grid container item spacing={1}>
          <FormControl className={classes.formControl} fullWidth>
            <StrongPasswordComp
              value={password}
              placement="right-start"
              pwRef={pwRef}
              tooltip
            >
              <TextValidator
                className={classes.margin}
                variant="outlined"
                fullWidth
                label={labels.password}
                value={password}
                type="password"
                onChange={(event) => {
                  setPassword(event.currentTarget.value);
                  setPwErr("");
                }}
                inputRef={pwRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        height: "auto",
                      }}
                    >
                      <PasswordIcon color="secondary" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <StrongPasswordComp tooltip placement="right-start">
                      <InputAdornment
                        position="end"
                        style={{
                          height: "auto",
                        }}
                      >
                        <InfoOutlinedIcon color="secondary" />
                      </InputAdornment>
                    </StrongPasswordComp>
                  ),
                }}
                validators={["required", "strongPassword"]}
                errorMessages={[
                  labels.passwordIsRequired,
                  labels.passwordDoesNotMeetPasswordPolicy,
                ]}
              />
            </StrongPasswordComp>
          </FormControl>
        </Grid>

        <Grid container item spacing={1}>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              className={classes.margin}
              variant="outlined"
              fullWidth
              label={labels.conPassword}
              value={conPassword}
              onChange={(event) => {
                setConPassword(event.currentTarget.value);
                setPwErr("");
              }}
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      height: "auto",
                    }}
                  >
                    <PasswordIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        {password && conPassword && password !== conPassword && (
          <Typography color="error" className={classes.internalErr}>
            The password are inconsistent
          </Typography>
        )}
        {pwErr && (
          <Typography color="error" className={classes.internalErr}>
            {pwErr}
          </Typography>
        )}
        <Grid container item spacing={1}>
          <FormControl
            className={`${classes.formControl} ${classes.formControlLogin}`}
            fullWidth
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // component="span"
              color="primary"
              className={classes.button}
              type="submit"
              // onClick={handleChangePassword}
              disabled={isLoggingIn}
            >
              {isLoggingIn ? ButtonSpinner : labels.changePassword}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default PasswordChangeComp;
